import { RectanglesPositions } from '@/components/common/LayoutWithLabel/types';

export const rectanglesPositions: RectanglesPositions = {
  desktop: [
    {
      x: 11,
      y: 99,
      width: 197,
      height: 24,
    },
    {
      x: 140,
      y: 179,
      width: 136,
      height: 24,
    },
    {
      x: -6,
      y: 359,
      width: 68,
      height: 17,
    },
  ],
  mobile: [],
};
