
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import Image from 'next/image';
import classes from './index.module.scss';
import { rectanglesPositions } from './rectanglesPositions';
import { H3, P } from '@/components/common/Typography';
import BackgroundRectangles from '@/components/common/LayoutWithLabel/components/BackgroundRectangles';
import { ArrowRight } from '@/components/ArrowRight';
import { HomeArticleCardData } from '@/types/Config/MediaAboutUs';
interface Props {
    data: HomeArticleCardData;
    disabledHover?: boolean;
}
const OurClientsArticle: React.FunctionComponent<Props> = ({ data: { logo, logoHover = logo, name, heading = '', title, url }, disabledHover = false, }) => {
    const [isHover, setIsHover] = useState(false);
    const { t, lang } = useTranslation('home');
    const isPlLocale = lang === 'pl';
    return (<a className={clsx(classes.anchor, isHover && classes.anchor_hover)} href={url} target="_blank" rel="noopener" onMouseEnter={() => {
            if (disabledHover)
                return;
            setIsHover(true);
        }} onMouseLeave={() => setIsHover(false)}>
      <div className={classes.imageWrapper}>
        <Image className={clsx(classes.image, classes.logotype, !isHover && classes.logotype_visible)} src={logo} width="150" height="150" alt={name} style={{
            objectFit: 'contain',
            maxWidth: '135px',
            maxHeight: '44px',
        }}/>
        <Image className={clsx(classes.image, classes.logotype, isHover && classes.logotype_visible)} src={logoHover} width="150" height="150" alt={name} style={{
            objectFit: 'contain',
            maxWidth: '135px',
            maxHeight: '44px',
        }}/>
      </div>
      <div className={classes.contentLayout}>
        <H3 className={clsx(classes.heading, isHover && classes.heading_hover)} disableMargin dangerouslySetInnerHTML={{
            __html: isPlLocale ? heading : t(heading),
        }}/>
        <P className={clsx(classes.paragraph, isHover && classes.paragraph_hover)} disableMargin dangerouslySetInnerHTML={{ __html: title }}/>
      </div>
      {!disabledHover && (<>
          <button className={classes.button} tabIndex={-1} aria-label={name}>
            <ArrowRight fill={isHover ? 'darkBlue' : 'grayBlue'}/>
          </button>
          <BackgroundRectangles rectangles={rectanglesPositions}/>
        </>)}
    </a>);
};
export default OurClientsArticle;

    async function __Next_Translate__getStaticProps__1910ce424aa__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/OurClients/components/OurClientsArticle/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce424aa__ as getStaticProps }
  